import React from "react";
import "../css/components/Triangle.css"

export const Triangle = ({ children, styles }) => {
    return (
        <>
            <div className={`${styles} triangle center-absolute`}></div>
            <div className="triangle-shadow center-absolute"></div>
            <div className="triangle-shadow triangle-shadow-left center-absolute"></div>
            <div className="triangle-shadow triangle-shadow-top center-absolute"></div>
        </>
    )
}