import React from "react";
import "../css/Layout.css"

//COMPONENTS
import { Footer } from "../components/Footer"

export const Layout = ({ children })=>{ 
    return(
        <div className="layout">
            {children}
            <Footer styles={"bg-primary"}/>   
        </div>
    )
}