import React, { createContext, useRef, useEffect, useState } from "react";
import { ethers } from "ethers";
import mobileDetect from "mobile-detect"

//SOURCES DATA
import abiContract from "../../json/contract.json"
import { userCredentials } from "../../config/userCredentials"

export const MetaMaskContext = createContext()

export const MetaMask = ({ children }) => {
    //MOBILE VARS
    const [isMobile, setIsMobile] = useState(false)
    const mobileDetector = new mobileDetect(window.navigator.userAgent)

    const prueba = "hello world"
    //TEXT
    const lilNessiansAvailable = useRef(null)

    //BUTTONS
    let mintBtn = useRef(null)
    let enableMetaMaskBtn = useRef(null)
    let disconnectMetamaskBtn = useRef(null)
    const tokenAmount = useRef(null)

    //LOADER
    const imgSpinner = useRef(null)

    //STATUS
    let status = useRef(null)
    const mintStatus = useRef(null)

    //FUNCTIONS

    //CONEXION A METAMASK
    function detectMetaMask() {
        console.log("detecting metamask...");
        if (typeof window.ethereum !== 'undefined') {
            return true
        } else {
            return false
        }
    }

    function connect() {
        console.log('Inside connect()')
        //ACCEDER A LAS CUENTAS DEL USUARIO
        window.ethereum.request({
            method: 'eth_requestAccounts'
        })
            .then(handleAccountsChanged)
            .catch((err) => {
                if (err.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    // If this happens, the user rejected the connection request.
                    console.log('Please connect to MetaMask.');
                    status.current.innerHTML = "You refused to connect Metamask"
                } else {
                    console.error(err);
                }
            });

        window.ethereum.on("accountsChanged", async function () {
            // Time to reload your interface with accounts[0]!
            const accounts = await window.ethereum.request({
                method: 'eth_requestAccounts'
            }).then(handleAccountsChanged);
            console.log(accounts);
        });
    }

    function handleAccountsChanged(accounts) {
        mintBtn.current.style.background = '#5F7EFC'
        console.log('Calling HandleChanged')
        let currentAccount = window.currentAccount
        addClass([enableMetaMaskBtn], "w-32")
        addClass([enableMetaMaskBtn], "truncate")

        if (accounts.length === 0) {
            console.log('Please connect to MetaMask.');
            enableMetaMaskBtn.current.innerHTML = "Connect with Metamask"
            mintBtn.current.setAttribute("disabled", true)
            mintBtn.current.classList.add("cursor-not-allowed")
        }
        else if (accounts[0] !== currentAccount) {
            currentAccount = accounts[0];
            enableMetaMaskBtn.current.innerHTML = currentAccount
            status.current.innerHTML = ""
            if (currentAccount != null) {
                // Set the button label
                enableMetaMaskBtn.current.innerHTML = currentAccount
            }

            disconnectMetamaskBtn.current.style.display = "block"
            mintBtn.current.removeAttribute("disabled")
            mintBtn.current.classList.remove("cursor-not-allowed")
            mintBtn.current.classList.remove("bg-gray-500")
            mintBtn.current.classList.add("bg-white")
            tokenAmount.current.removeAttribute("disabled")
        }
        else {
            tokenAmount.current.setAttribute("disabled", true)
            enableMetaMaskBtn.current.innerHTML = currentAccount
            disconnectMetamaskBtn.current.style.diplay = "block"
            mintBtn.current.removeAttribute("disabled")
            mintBtn.current.classList.remove("cursor-not-allowed")
            mintBtn.current.classList.remove("bg-white")
            mintBtn.current.classList.add("cursor-not-allowed")
        }
        console.log('WalletAddress in HandleAccountChanged =' + currentAccount)
    }

    async function getTotalSupply() {
        console.log('Inside getTotalSuplly()');

        const contractAddress = userCredentials.NFT_CONTRACT_ADDRESS;

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const abi = abiContract.abi

        var contract = new ethers.Contract(contractAddress, abi, provider);


        var callPromise = contract.totalSupply();

        callPromise.then(function (result) {
            console.log(result);
            console.log(result.toNumber());
            console.log(`${result.toNumber()} LilNessians Available / Total Supply 5000`);
            // lilNessiansAvailable.current.innerHTML = `${result.toNumber()} LilGiblis Available / Total Supply 5069`
            // lilNessiansAvailable.current.innerHTML = `Only ${result.toNumber()} Lil Nessians`

        });
    }

    async function mintNFT() {
        console.log('Inside mintNFT()');
        console.log(userCredentials.NFT_CONTRACT_ADDRESS);
        displaySpinnerMintLoading(true);

        const contractAddress = userCredentials.NFT_CONTRACT_ADDRESS;

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const tokenAmountInt = Number(tokenAmount.current.value)
        const price = (tokenAmountInt * 0).toFixed(2)

        const abi = abiContract.abi
        const nftContract = new ethers.Contract(contractAddress, abi, signer)
        let nftTxn = nftContract.mintNFTs(tokenAmountInt, { value: ethers.utils.parseEther(`${price}`) })
            .then(nftTxn => {
                console.log("Mining... please wait");
                nftTxn.wait()
                    .then(response => {
                        displaySpinnerMintLoading(false);
                        mintStatus.current.innerHTML = ""
                        mintStatus.current.innerHTML = `<p> Minted, see transaction: <a class="block w-72 md:w-auto truncate" href="${userCredentials.ETHER_SCAN_URL}/tx/${nftTxn.hash}" target="_blank" >${userCredentials.ETHER_SCAN_URL}/tx/${nftTxn.hash}</a></p>`
                        getTotalSupply()
                        console.log(`Minted, see transaction: <%= etherScanURL %>/tx/${nftTxn.hash}`);
                    })
            })
            .catch((errors) => {
                if (errors) {
                    displaySpinnerMintLoading(false);
                    mintStatus.current.innerHTML = ""
                    mintStatus.current.innerHTML = `<p>code: ${JSON.stringify(errors.code)}</p>`
                    mintStatus.current.innerHTML = `<p>message: ${JSON.stringify(errors.message)}</p>`
                }
                console.log('Errors', errors);
            })
    }

    //DOM MANIPULATION
    const setAtribute = (domElements, atribute, value) => {
        domElements.map(element => {
            return element.current.setAttribute(atribute, value)
        })
    }

    const hideElements = (domElements) => {
        domElements.map(element => {
            return element.current.style.display = "none"
        })
    }

    const addClass = (domElements, className) => {
        domElements.map(element => {
            return element.current.classList.add(className)
        })
    }

    // HANDLE CLICKS
    const mintBtnHandleClick = () => {
        console.log('Before calling mintNFT()');
        const tokenAmountInt = Number(tokenAmount.current.value)
        mintStatus.current.textContent = ""

        if (tokenAmountInt >= 1 && tokenAmountInt <= 10) {
            mintNFT();
        } else {
            mintStatus.current.textContent = "";
            mintStatus.current.innerHTML = "<p class='text-red-500'>Please select a valid value between 1 and 10</p>"
            tokenAmount.current.focus()
        }
    }

    const logOutMetaMask = () => {
        console.log("logging out");
        tokenAmount.current.setAttribute("disabled", true)
        enableMetaMaskBtn.current.removeAttribute("disabled")
        enableMetaMaskBtn.current.classList.add("w-84")
        enableMetaMaskBtn.current.setAttribute("word-wrap", "break-word")
        enableMetaMaskBtn.current.classList.remove("w-32")
        disconnectMetamaskBtn.current.style.display = "none"
        enableMetaMaskBtn.current.innerHTML = "Please connect to Metamask"

        mintBtn.current.setAttribute("disabled", true)
        mintBtn.current.classList.add("cursor-not-allowed")
        mintBtn.current.classList.remove("bg-green-200")
        mintBtn.current.classList.add("bg-gray-500")
        enableMetaMaskBtn.current.classList.remove("truncate")
        mintBtn.current.style.background = 'gray'
        hideElements([lilNessiansAvailable])
    }

    const enableMetaMaskBtnClick = () => {
        console.log('enableMetamask was clicked');
        lilNessiansAvailable.current.style.display = "block"
        connect();
    }

    //LOADERS
    function displaySpinnerMintLoading(display) {
        if (display) {
            mintBtn.current.setAttribute("disabled", true)
            mintBtn.current.classList.add("cursor-not-allowed")
            imgSpinner.current.classList.remove("hidden-gif")
        } else {
            mintBtn.current.removeAttribute("disabled")
            mintBtn.current.classList.remove("cursor-not-allowed")
            imgSpinner.current.classList.add('hidden-gif')
        }
    }


    

    useEffect(() => {
        console.log(disconnectMetamaskBtn);
        console.log('document ready');
        getTotalSupply()
        hideElements([disconnectMetamaskBtn])
        setAtribute([mintBtn, tokenAmount], "disabled", false)

        if (mobileDetector.mobile()) {
            setIsMobile(true)
        }
        let m = detectMetaMask()
        if (m) {
            setIsMobile(false)
            setAtribute([enableMetaMaskBtn])
            hideElements([disconnectMetamaskBtn])
            connect() // Make sure the connected wallet is being returned  
        }
        else {
            setAtribute([enableMetaMaskBtn])
            hideElements([disconnectMetamaskBtn])
            enableMetaMaskBtn.current.innerHTML = "Please Setup MetaMask"
        }
    }, [isMobile])

    return (
        <MetaMaskContext.Provider value={{
            prueba,
            //BUTTONS
            mintBtn,
            enableMetaMaskBtn,
            disconnectMetamaskBtn,

            //COUNTER
            tokenAmount,

            //LOADER
            imgSpinner,

            //STATUS
            status,
            mintStatus,

            //TEXT
            lilNessiansAvailable,

            //FUNCTIONS
            mintBtnHandleClick,
            logOutMetaMask,
            enableMetaMaskBtnClick,

            //MOBILE
            isMobile,

        }}>
            {children}
        </MetaMaskContext.Provider>
    )
}
