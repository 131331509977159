import React, { useContext, useState, useRef } from "react";
import { MetaMaskContext } from "../context/metaMask/MetaMaskContext";
import "../css/components/HeaderHome.css"

//SOURCES
import twitterIcon from "../img/icons/twitter-circle.svg"
import etherscan from "../img/icons/etherscan.webp"
import mainImg from "../img/assets/title.png"

// COMPONENTS
import { Button } from "./Button";
import { BoxContainer } from "../components/BoxContainer";
import { Link } from "react-router-dom";

export const HeaderHome = ({ styles }) => {
    const {
        //BUTTONS
        disconnectMetamaskBtn,
        enableMetaMaskBtn,

        //STATUS
        status,

        //FUNCTIONS
        logOutMetaMask,
        enableMetaMaskBtnClick,

        //MOBILE
        isMobile,
        //FUNTIONS
    } = useContext(MetaMaskContext)

    return (

        <header className={styles}>
            <nav className="p-5 mb-10 lg:mb-0 ">
                <div className="w-full max-w-screen-2xl mx-auto flex justify-between">
                    <div className="inline-block w-12 sm:w-auto">
                        <a href="https://twitter.com/TGiblis" className="inline-block mx-2" target="_blank">
                            <img src={twitterIcon} alt="Twitter" className="lg:w-7 w-8" />
                        </a>
                        <a href="https://etherscan.io/address/0x6DCD5956b17d252559917423b0e8081DA0CAc5b2" target='_blank' className="inline-block mx-2 lg:mx-1" >
                            <img src={etherscan} alt="Discord" className="lg:w-7 bg-white" />
                        </a>
                    </div>


                    <div className="flex content-center items-center row-start-2">

                        <button onClick={logOutMetaMask} ref={disconnectMetamaskBtn} id="disconnectMetamask" className="rounded-md py-3 px-4 bg-white text-xl md:w-auto mr-5 box-container">
                            Disconnect Metamask
                        </button>
                        {
                            isMobile ?
                                <a href="https://metamask.app.link/dapp/lilnessians.io/">
                                    <button ref={enableMetaMaskBtn} id="enableMetamask" className="rounded-md py-3 px-4 bg-white mx-2 text-xl md:w-auto box-container">
                                        Connect wallet
                                    </button>
                                </a>
                                :
                                <button onClick={enableMetaMaskBtnClick} ref={enableMetaMaskBtn} id="enableMetamask" className="rounded-md py-3 px-4 bg-white text-xl md:w-auto mr-5 box-container">
                                    Connect wallet
                                </button>
                        }
                        <div ref={status} id="status mx-2" className="text-white" />
                    </div>
                </div>
            </nav>

            <div className="logo">
                <Link to="/" className="hover:cursor-pointer">
                    <img src={mainImg} alt="" className="block mx-auto " />
                </Link>
                {/* <h1 className="main-title text-white text-7xl text-center mb-6">LIL GIBLIS</h1> */}
                <h3 className="text-white text-5xl text-center">
                    Gimmi a Gibli</h3>
            </div>
        </header>
    )
}