import React, { Fragment, useContext } from "react";
import { MetaMaskContext } from "../context/metaMask/MetaMaskContext";
import "../css/components/Home.css"

//COMPONENTS
import { HeaderHome } from "../components/HeaderHome";
import { SelectMintQuantity } from "../components/SelectMintQuantity";
import { BoxContainer } from "../components/BoxContainer"
import { Triangle } from "../components/Triangle"

//SOURCES ICONS
import loaderGif from "../img/assets/loader.gif"
import { IconLink } from "../components/Icon-link";

//SOURCES IMAGES
import banner from "../img/background/banner-giblis.png"
import av1 from "../img/assets/av1.png"
import av2 from "../img/assets/av2.png"
import av3 from "../img/assets/av3.png"
import av4 from "../img/assets/av4.png"
import av5 from "../img/assets/av5.png"

// ORNAMENTS
import decor1 from "../img/assets/website/website.01.png"
import decor2 from "../img/assets/website/website.02.png"
import decor3 from "../img/assets/website/website.03.png"
import decor4 from "../img/assets/website/website.04.png"
import decor5 from "../img/assets/website/website.05.png"
import decor6 from "../img/assets/website/website.06.png"
import decor7 from "../img/assets/website/website.07.png"



//SOURCES DATA




export const Home = ({ value }) => {
    const {
        mintBtn,
        imgSpinner,
        mintStatus,
        lilNessiansAvailable,

        //FUNCTIONS
        mintBtnHandleClick,
    } = useContext(MetaMaskContext)


    return (
        <Fragment>
            <div className="main-container pb-48 sm:pb-72 md:pb-80 lg:pb-96 scroll-bar relative">
                <HeaderHome />
                <h4 className="lilNessiansAvailable" ref={lilNessiansAvailable}></h4>
                {/* <p className="mt-8 uppercase text-lg text-white lg:text-base text-center ">0.065 ETH per Lil Gibli</p> */}
                <div className="flex flex-col space-y-2 justify-center items-center">
                    <SelectMintQuantity />
                    {/* <button disabled onclick="" class="rounded-md bg-white text-gray-900 uppercase mx-auto my-6 lg:my-0 py-6 px-24 m lg:py-3 lg:px-14  ">
                        Coming Soon
                    </button> */}
                    <button onClick={mintBtnHandleClick} ref={mintBtn} className="hidden mint-btn rounded-md uppercase mx-auto my-6 lg:my-0 py-6 px-24 m lg:py-3 lg:px-14 cursor-not-allowed">
                        <img ref={imgSpinner} src={loaderGif} alt="spinner loader" className="loader-gif hidden-gif" />
                        Mint NFT
                    </button>
                    <h2 className="text-9xl text-center text-black">SOLD OUT</h2>
                    <div ref={mintStatus} id="mintStatus">
                        {/* <p> Minted, see transaction: <a href="${userCredentials.ETHER_SCAN_URL}/tx/${nftTxn.hash}" target="_blank" >Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit labore vero deleniti, dolores delectus, ea nihil earum possimus obcaecati deserunt expedita magnam quia assumenda veritatis cum sit, fuga sint! Id aperiam sunt ex excepturi cum consequatur, ipsum dicta eligendi consectetur!</a></p> */}

                    </div>
                </div>
                <BoxContainer styles={"box-container w-3/4 md:w-3/5 bg-white mt-14 p-12 mx-auto relative"}>
                    <img src={decor1} alt="" className="decor decor1" />
                    <h2 className="text-4xl mb-3">WHO WE ARE ?</h2>
                    <p className="text-xl">Lil Gibli is an upcoming NFT collectible project centered around community, collaboration, and adventure. The collection of 5,000 Giblis are programmatically generated using a unique combination of traits living on the Ethereum blockchain. </p>
                    <p className="text-xl mt-6">Each Lil Gibli makes you a part of The Gibli Family – not only an epic PFP, but grants you access to The Lil Gibli world – access to exclusive members only events, world building collaboration, raffles, community giveaways and much more. </p>
                </BoxContainer>
                <img src={banner} alt="" className="banner" />
                <img src={decor2} alt="" className="decor decor2" />
                <img src={decor3} alt="" className="decor decor3" />
            </div>

            <div className="faqs-container info-container py-40 relative">
                <BoxContainer styles={"box-container w-3/4 w-3/4 md:w-3/5 bg-white p-14 relative mx-auto"}>
                    <BoxContainer styles={"info-box-title center-absolute box-container-2 bg-white p-3 "}>
                        <h3 className="text-4xl text-center">Faqs</h3>
                    </BoxContainer>
                    <ol className="list-decimal">
                        <li className="text-2xl mb-5 sm:mb-5"> Public sale - 05/27/2022</li>
                        <li className="text-2xl mb-5 sm:mb-5">Mint Cost: Free</li>
                        <li className="text-2xl mb-5 sm:mb-5">Total Supply: 5,000</li>
                        <li className="text-2xl mb-5 sm:mb-3">Max Mint: 10</li>
                    </ol>
                    <img src={decor4} alt="" className="decor decor4" />
                    <img src={decor1} alt="" className="decor faqs-decor" />
                </BoxContainer>
                <img src={decor5} alt="" className="decor decor5" />
            </div>

            <div className="roadmap-container info-container py-48 bg-aux5 relative">
                <BoxContainer styles={"box-container w-3/4 w-3/4 md:w-3/5 bg-white p-14 relative mx-auto"}>
                    <div className="">
                        <Triangle styles={""} />
                        <h3 className="ouroadmap-title text-3xl text-black sm:text-4xl center-absolute text-white z-10">
                            Our Roadmap
                        </h3>
                    </div>
                    <p className="text-xl">
                        The Gibli Family is setting out to create a long-term project with community and friendship as its core tenets. To do this we will embark on a journey of the imagination, developing bonds within The Family and branching out along the way. The future focus of our family will always begin and end with the community. Our first goal upon completion of minting will be to create a meeting place for all to come together and share in our vision.
                    </p>
                    <img src={decor7} alt="" className="decor decor7" />
                </BoxContainer>
                <img src={decor6} alt="" className="decor decor6" />
                <img src={decor3} alt="" className="decor roadmap-decor center-absolute"/>
                <img src={decor2} alt=""  className="decor roadmap-decor2"/>
            </div>

            <div className="team-container info-container py-20 ">
                <BoxContainer styles={"info-box-title box-container-2 bg-white p-3 w-2/4 mb-32 mx-auto"}>
                    <h3 className="text-4xl text-center">Team</h3>
                </BoxContainer>
                <div className="team flex flex-wrap justify-center sm:justify-evenly mx-auto">
                    <BoxContainer styles={"box-container team-member bg-white relative px-8 pt-8 pb-5 mt-32 lg:mt-0"}>
                        <img src={av1} alt="" className="center-absolute team-picture" />
                        <h3 className=" text-2xl tracking-wider text-center mt-14"><b>The Great Gibli</b></h3>
                        <p className=" text-xl text-center mt-5"> Kicked out of Hogwarts for using Black Magic, he turned his attention to world/character building. With a background in literature, The Great Gibli has 15+ years of story building under his belt and now moves his focus into establishing The Gibli Universe.</p>
                    </BoxContainer>
                    <BoxContainer styles={"box-container team-member bg-white relative px-8 pt-8 pb-5 mt-32 lg:mt-0"}>
                        <img src={av2} alt="" className="center-absolute team-picture" />
                        <h3 className=" text-2xl tracking-wider text-center mt-14"><b>Gibreee</b></h3>
                        <p className=" text-xl text-center mt-5">I Would Like to Thank My Arms for Always Being by My Side. My Legs for Always Supporting Me and Finally My Fingers, Because I Could Always Count On Them</p>
                    </BoxContainer>
                    <BoxContainer styles={"box-container team-member bg-white relative px-8 pt-8 pb-5 mt-32 lg:mt-0"}>
                        <img src={av3} alt="" className="center-absolute team-picture" />
                        <h3 className=" text-2xl tracking-wider text-center mt-14"><b>Lil Lil Gibli</b></h3>
                        <p className=" text-xl text-center mt-5"> A Gibli trained in the mountains and chosen to create the best and fascinating nft projects, a truly talented developer.</p>
                    </BoxContainer>
                    <BoxContainer styles={"box-container team-member bg-white relative px-8 pt-8 pb-5 mt-32 lg:mt-0"}>
                        <img src={av4} alt="" className="center-absolute team-picture" />
                        <h3 className=" text-2xl tracking-wider text-center mt-14"><b>Gibmeister</b></h3>
                        <p className=" text-xl text-center mt-5">
                        Born from the ashes of Solidity, an object-oriented programming instinct flows through his veins, allowing him to create any kind of smart contracts on the blockchain
                        </p>
                    </BoxContainer>
                    <BoxContainer styles={"box-container team-member bg-white relative px-8 pt-8 pb-5 mt-32 lg:mt-0"}>
                        <img src={av5} alt="" className="center-absolute team-picture" />
                        <h3 className=" text-2xl tracking-wider text-center mt-14"><b>Gibtavo</b></h3>
                        <p className=" text-xl text-center mt-5">A wise Project Manager that has been wandering on the blockchain for ages, gathering knowledge and looking for the best of the best Giblis, all this to build Teams of awesomeness and deliver cool Projects.</p>
                    </BoxContainer>
                </div>
            </div>
        </Fragment>
    )
}