import React, { useState, useEffect, useContext } from "react";
import { MetaMaskContext } from "../context/metaMask/MetaMaskContext";
import { v4 as uuidv4 } from 'uuid';
import "../css/components/SelectMintQuantity.css"

export const SelectMintQuantity = () => {
    const { tokenAmount } = useContext(MetaMaskContext)
    const [numbers, setNumbers] = useState()
    const maxNfts = 10

    useEffect(()=>{
        const options = []
        
        for (let i = 1; i <= maxNfts; i++) {
            options.push(i)
        }
        setNumbers(options)
    }, [])

    return (
        <>
        {/* <button onClick={handleClick}>agregar</button> */}
            <label htmlFor="tokenAmount" className="hidden"></label>
            <select name="tokenAmount" id="tokenAmount" ref={tokenAmount} className="hidden form-input px-4 py-3 rounded-xl text-gray-900 w-16">
                {
                    numbers!== undefined && numbers.map(element=>{
                        return <option value={element} key={uuidv4()}>{element}</option>
                    })
                }
            </select>
        </>
    )
}