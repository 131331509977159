import React from "react"
import { Link } from "react-router-dom";

import twitterIcon from "../img/icons/twitter-circle.svg"
import mediumIcon from "../img/icons/medium-icon.svg"


export const Footer = ({ styles }) => {
    return (
        <footer className={`flex justify-around ${styles}`}>
            <div className="w-2/5 flex flex-col my-3">
                <span className="text-white my-1 text-xl">Copyright © 2022</span>
                {/* <span className="hidden sm:inline">|</span> */}
                {/* <Link to="/privacy-policy" className="text-white my-1 hover:underline text-xl">
                    Privacy Policy
                </Link> */}
                {/* <span className="hidden sm:inline">|</span> */}
                {/* <Link to="/terms-and-conditions" className="text-white my-1 hover:underline text-xl ">
                    Terms and Conditions
                </Link> */}
            </div>
            <div className="flex justify-end items-center w-2/5">
                <a href="https://twitter.com/TGiblis" className="inline-block mx-2" target="_blank">
                    <img src={twitterIcon} alt="Twitter" className="lg:w-7 w-8" />
                </a>
                {/* <a href="" className="inline-block mx-2" >
                    <img src={mediumIcon} alt="Discord" className="lg:w-7 w-8 bg-white" />
                </a> */}
            </div>
        </footer>
    )
}