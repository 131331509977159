import { BrowserRouter, Routes, Route } from "react-router-dom"
import { MetaMask } from "../context/metaMask/MetaMaskContext"

//STYLES
import "../css/reader.css"
import "../index.css"

//COMPONENTS
import { Layout } from "../containers/Layout"
import { Home } from "../pages/Home";
import { Privacy_policy } from "../pages/privacy-policy";
import { Terms_and_conditions } from "../pages/terms-and-conditions";
import { Error404 } from "../pages/Error404";


export const App = () => {
    return (
        <MetaMask>
        
        <BrowserRouter>
            <Layout>
                <Routes>    
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/privacy-policy" element={<Privacy_policy/>}/>
                    <Route exact path="/terms-and-conditions" element={<Terms_and_conditions/>}/>
                    <Route path="*" element={<Error404/>}/>
                </Routes>
            </Layout>
        </BrowserRouter>
        </MetaMask>
    )
}