import React from "react";
import "../css/components/BoxContainer.css"

export const BoxContainer = ({children, styles})=>{
    return(
        <>
            <div className={`${styles}`}>
                    {children}
            </div>
        </>
    )
}